import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { user } from '../../../user';
import { Checkbox, ContextMenu, Popover } from '../../controls';
import { Confirm } from '../../alerts';
import { companiesActions as actions } from '../../../actions';
import { companyStatuses, constants, memberColumns, roles, routes, userStatuses } from '../../../constants';
import { getMembers } from '../../../selectors';
import { EmptyPlaceholder, OnHoverTooltip, Preloader } from '../../common';
import { LastLoginHistoryPopup } from './LastLoginHistoryPopup';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { RejectReasonPopup } from '../../common/RejectReasonPopup';
import { UserRequestStatus } from '../../../types/management/UserRequestStatus';
import { userRequestAdminActions } from '../../../actions/user-request-admin.actions';
import { ListBuilder } from '../../../utils/ListBuilder';
import IconSVG from "../../../styles/svg-icons";
import { MembersListPendingUserRequestOptionsButtons } from './MembersListPendingUserRequestOptionsButtons';
import { RequestUserStatusDetails } from '../../profile/tabs/manage/users/RequestUserStatusDetails';

class MemberList extends Component {
    state = {
        memberForRejectReasonPopup: null,
        memberForSendTemporaryPassword: null,
    };

    getOrderByCssClasses = column => this.props.orderBy.column === column ? this.props.orderBy.direction : '';

    getStatusChangeConfirmationMessage = member => {
        const status = member.status === userStatuses.blocked.statusCode ? 'unblock' : 'block';
        return `Do you really want to ${status} ${member.fullName()}?`;
    };

    getMenuItems = member => {
        const { /*companies,*/ dispatch, history, requestStatusGetUserList } = this.props;
        // const company = companies.find(c => c.id === member.companyId);

        const edit = { text: 'Edit', action: () => history.push(routes.manageCompaniesEditUserUrl(member.id)) };
        const unblock = {
            text: 'Unblock', action: () => dispatch(actions.memberStatusChangeConfirmation(true, member))
        };
        const block = { text: 'Block', action: () => this.setState({ memberForRejectReasonPopup: member }) };
        const reSendInvite = {
            sectionBlock: true,
            text: 'Re-Send Invite', action: () => dispatch(actions.resendInviteConfirmation(true, member))
        };
        const resetPassword = {
            sectionBlock: true,
            text: 'Reset Password', action: () => dispatch(actions.resetPasswordConfirmation(true, member))
        };
        const TFA = {
            sectionBlock: true,
            text: member.tfaEnabled ? 'Disable TFA' : 'Enable TFA',
            action: () => dispatch(actions.chnageTfaEnabledStatusConfirmation(true, member))
        };
        const deleteMember = {
            text: 'Delete',
            disabled: requestStatusGetUserList,
            action: () => dispatch(actions.deleteUser(member.id))
        };
        const sendTemporaryPassword = {
            text: 'Set temp pass',
            action: () => this.setState({ memberForSendTemporaryPassword: member })
        };

        return new ListBuilder()
            .add(edit)
            .addWhen(() => member.id !== user.current().id && member.status !== userStatuses.blocked.statusCode, block)
            .addWhen(
                () =>
                    member.id !== user.current().id &&
                    member.status === userStatuses.blocked.statusCode &&
                    member.canUnblock,
                unblock
            )
            .addWhen(
                () => member.status === userStatuses.blocked.statusCode,
                deleteMember
            )
            .addWhen(() =>
                member.status === userStatuses.invited.statusCode || member.status === userStatuses.notInivited.statusCode,
                reSendInvite
            )
            .addWhen(() => member.status === userStatuses.active.statusCode, resetPassword)
            .addWhen(() =>
                member.status === userStatuses.active.statusCode || member.status === userStatuses.invited.statusCode,
                sendTemporaryPassword
            )
            .add(TFA)
            .result();
    };

    getBlockedUserRequestMenuItems = userRequest => {
        return [{
            text: 'Unblock',
            action: () => this.props.dispatch(userRequestAdminActions.unblock(userRequest.id, userRequest.concurrencyStamp))
        }, {
            text: 'Delete',
            action: () => this.props.dispatch(userRequestAdminActions.deleteUserRequest(userRequest.id))
        }];
    };

    getOfacField = (member) => {
        const { dispatch, membersOfacRequestStatus } = this.props;
        return (
            <Preloader inProgress={membersOfacRequestStatus[member.id]} fullScreen={false} small={true}>
                <Checkbox
                    checked={member.ofac || false}
                    disabled={user.current().id === member.id}
                    onChange={(e) => dispatch(actions.setMemberOfacStatus(member.id, e.target.checked))}
                />
            </Preloader>
        )
    };

    handleBlockUser = message => {
        const { dispatch } = this.props;
        dispatch(actions.changeMemberBlockedStatus(this.state.memberForRejectReasonPopup, message));
        this.setState({ memberForRejectReasonPopup: null })
    };

    handleConfirmSendTemporaryPassword = () => {
        this.props.dispatch(actions.setTempPassword(this.state.memberForSendTemporaryPassword.id));
        this.setState({ memberForSendTemporaryPassword: null });
    };

    getRowClassNames = member => {
        const memberId = member.id;
        const { statusChangeConfirmation, editMember, resendInviteConfirmation, tfaStatusChangeConfirmation, resetPasswordConfirmation } = this.props;
        const highlighted = !!(
            (tfaStatusChangeConfirmation && tfaStatusChangeConfirmation.member && tfaStatusChangeConfirmation.member.id === memberId) ||
            (statusChangeConfirmation && statusChangeConfirmation.member && statusChangeConfirmation.member.id === memberId) ||
            (resendInviteConfirmation && resendInviteConfirmation.member && resendInviteConfirmation.member.id === memberId) ||
            (resetPasswordConfirmation && resetPasswordConfirmation.member && resetPasswordConfirmation.member.id === memberId) ||
            (editMember && editMember.user && editMember.user.id === memberId) ||
            (this.state.memberForRejectReasonPopup && this.state.memberForRejectReasonPopup.id === memberId)
        );
        return classNames({
            highlighted,
            'user-request': member.isUserRequest && member.status === UserRequestStatus.Pending
        });
    };

    handleResetFilterAndSearch = () => {
        const { dispatch, handleSearchTermChange } = this.props;
        handleSearchTermChange('');
        dispatch(actions.resetMemberFilters());
    }

    render = () => {
        const {
            isInitCompleted,
            members,
            dispatch,
            userLastLoginHistory,
            statusChangeConfirmation,
            resetPasswordConfirmation,
            resendInviteConfirmation,
            tfaStatusChangeConfirmation
        } = this.props;

        if (isInitCompleted && !members.length) {
            return <EmptyPlaceholder onResetClick={this.handleResetFilterAndSearch}/>;
        }

        const getRolesByString = (roleList) => roleList && roleList.length
            ? [...roleList].sort((a, b) => a.localeCompare(b)).map(r => roles.getTitle(r)).join(', ')
            : '–'

        const memberRows = members.map(m =>
            <tr key={`${m.id}-${m.isUserRequest ? 'user-request' : 'user'}`} className={this.getRowClassNames(m)}>
                <td>
                    <button
                        type="button"
                        className="btn btn-link text-ellipsis"
                        onClick={() => dispatch(actions.showMemberCompany(m))}
                        title={m.companyName}
                    >
                        {m.companyName}
                    </button>
                </td>
                <td>
                    <span
                        className={'first-letter status ' + companyStatuses.getByStatusCode(m.companyStatus).className}>
                        {companyStatuses.getByStatusCode(m.companyStatus).title}
                    </span>
                </td>
                <td>
                    <OnHoverTooltip overlay={getRolesByString(m.roles)} wrapperClass="text-ellipsis">
                        {getRolesByString(m.roles)}
                    </OnHoverTooltip>
                </td>
                <td className="data-user">
                    <Popover
                        title={m.fullName()}
                        actionButton={<button className="btn pseudo-link text-ellipsis">{m.fullName()}</button>}
                    >
                        <ul className="contacts-cards">
                            <li>
                                <span><a href={`mailto:${m.email}`}>{m.email}</a></span>
                                <span>{m.phone || '–'}</span>
                            </li>
                        </ul>
                        <div className="billing-info-details display-none">
                            <h2 className="title-border">Billing Information</h2>
                            <div className="flex-row align-items-flex-start">
                                <dl className="definition-list style01">
                                    <dt>Subscription Plan</dt>
                                    <dd>{m.subscription || '–'}</dd>
                                </dl>
                            </div>
                        </div>
                    </Popover>
                </td>
                <td>
                    <RequestUserStatusDetails
                        userStatus={m.isUserRequest ? undefined : m.status}
                        userRequestStatus={m.isUserRequest ? m.status : undefined}
                        blockReason={m.blockReason}
                    />
                </td>
                <td>
                    {
                        m.dateLastLoginUtc ?
                            <button
                                className="btn pseudo-link"
                                onClick={() => dispatch(actions.getUserLoginHistory(m))}
                            >
                                {dateTimeUtils.utcToLocalString(m.dateLastLoginUtc, constants.dateFormat4)}
                            </button> : constants.emptyPlaceholder
                    }
                </td>
                <td>{dateTimeUtils.utcToLocalString(m.dateRegisteredUtc, constants.dateFormat4)}</td>
                <td>
                    <OnHoverTooltip overlay={m.createdBy}>
                        <span className="text-ellipsis">{m.createdBy || '–'}</span>
                    </OnHoverTooltip>
                </td>
                <td className="text-right">{!m.isUserRequest && this.getOfacField(m)}</td>
                <td className="text-right">
                    {
                        !m.isUserRequest && <Checkbox checked={m.tfaEnabled} disabled={true} />
                    }
                </td>
                <td className="text-right">
                    {!m.isUserRequest && user.current().id !== m.id && <ContextMenu menuItems={this.getMenuItems(m)} />}
                    {m.isUserRequest && m.status === UserRequestStatus.Blocked &&
                        <ContextMenu menuItems={this.getBlockedUserRequestMenuItems(m)} />}
                    {m.isUserRequest && m.status === UserRequestStatus.Pending &&
                        <MembersListPendingUserRequestOptionsButtons
                            userRequestId={m.isUserRequest ? m.id : undefined}
                            concurrencyStamp={m.concurrencyStamp} />}
                </td>
            </tr>
        );

        return (
            <div className="container">
                <Preloader inProgress={!isInitCompleted}>
                    <div className="table-head">
                        <table className="main-table">
                            <colgroup>
                                <col width="14%" />
                                <col width="10%" />
                                <col width="9%" />
                                <col width="8%" />
                                <col width="10%" />
                                <col width="8%" />
                                <col width="8%" />
                                <col width="17%" />
                                <col width="5%" />
                                <col width="4%" />
                                <col width="7%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.companyName)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.companyName))}
                                    >
                                        Company
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.companyStatus)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.companyStatus))}
                                    >
                                        Comp. Status
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    <th>
                                        User Role
                                    </th>
                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.fullName)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.fullName))}
                                    >
                                        User
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.status)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.status))}
                                    >
                                        Status
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    {/*
                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.email)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.email))}
                                    >
                                        Email
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.phone)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.phone))}
                                    >
                                        Phone
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    */}

                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.dateLastLogin)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.dateLastLogin))}
                                    >
                                        Last Login
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.dateRegistered)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.dateRegistered))}
                                    >
                                        Reg. Date
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    <th
                                        className={`cell-sort ${this.getOrderByCssClasses(memberColumns.createdBy)}`}
                                        onClick={() => dispatch(actions.orderMembersBy(memberColumns.createdBy))}
                                    >
                                        Reg. by
                                        <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                    </th>
                                    <th className="text-right">
                                        OFAC
                                    </th>
                                    <th className="text-right">
                                        TFA
                                    </th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="manage-list container-flex">
                        <table className="main-table">
                            <colgroup>
                                <col width="14%" />
                                <col width="10%" />
                                <col width="9%" />
                                <col width="8%" />
                                <col width="10%" />
                                <col width="8%" />
                                <col width="8%" />
                                <col width="17%" />
                                <col width="5%" />
                                <col width="4%" />
                                <col width="7%" />
                            </colgroup>
                            <tbody>
                                {memberRows}
                            </tbody>
                        </table>
                    </div>

                    {
                        statusChangeConfirmation &&
                        statusChangeConfirmation.member &&
                        <Confirm
                            text={this.getStatusChangeConfirmationMessage(statusChangeConfirmation.member)}
                            onCancel={() => dispatch(actions.memberStatusChangeConfirmation(false))}
                            onConfirm={() => dispatch(actions.changeMemberBlockedStatus(statusChangeConfirmation.member))}
                        />
                    }
                    {
                        resetPasswordConfirmation &&
                        resetPasswordConfirmation.member &&
                        <Confirm
                            text={`Do you really want to reset password for ${resetPasswordConfirmation.member.fullName()} account?`}
                            onCancel={() => dispatch(actions.resetPasswordConfirmation(false))}
                            onConfirm={() => dispatch(actions.resetPassword(resetPasswordConfirmation.member))}
                        />
                    }
                    {
                        resendInviteConfirmation &&
                        resendInviteConfirmation.member &&
                        <Confirm
                            text={`Do you really want to send the invitation to ${resendInviteConfirmation.member.fullName()}?`}
                            onCancel={() => dispatch(actions.resendInviteConfirmation(false))}
                            onConfirm={() => dispatch(actions.resendInvite(resendInviteConfirmation.member))}
                        />
                    }
                    {
                        tfaStatusChangeConfirmation &&
                        tfaStatusChangeConfirmation.member &&
                        <Confirm
                            text={`Do you really want to ${tfaStatusChangeConfirmation.member.tfaEnabled ? "disable" : 'enable'} two-factor authentication for ${tfaStatusChangeConfirmation.member.fullName()}?`}
                            onCancel={() => dispatch(actions.chnageTfaEnabledStatusConfirmation(false))}
                            onConfirm={() => dispatch(actions.chnageTfaEnabledStatus(tfaStatusChangeConfirmation.member))}
                        />
                    }
                    {
                        this.state.memberForRejectReasonPopup &&
                        <RejectReasonPopup
                            onClose={() => this.setState({ memberForRejectReasonPopup: null })}
                            onReject={this.handleBlockUser}
                            comfirmButtonText="Block"
                            title="Blocked Reason"
                        />
                    }
                    <LastLoginHistoryPopup userLastLoginHistory={userLastLoginHistory} />
                    {!!this.state.memberForSendTemporaryPassword && (
                        <Confirm
                            text={`Do you really want to send a temporary password to ${this.state.memberForSendTemporaryPassword.firstName} ${this.state.memberForSendTemporaryPassword.lastName}?`}
                            onCancel={() => this.setState({ memberForSendTemporaryPassword: null })}
                            onConfirm={this.handleConfirmSendTemporaryPassword}
                            confirmButtonText={'Confirm'}
                        />
                    )}
                </Preloader>
            </div>
        );
    }
}

const mapStateToProps = ({ companies, changeSubscription }) => ({
    isInitCompleted: companies.isInitCompleted,
    members: getMembers(companies),
    filter: companies.memberFilter,
    orderBy: companies.memberOrderBy,
    userLastLoginHistory: companies.userLastLoginHistory,
    statusChangeConfirmation: companies.memberStatusChangeConfirmation,
    resetPasswordConfirmation: companies.memberResetPasswordConfirmation,
    resendInviteConfirmation: companies.memberResendInviteConfirmation,
    membersOfacRequestStatus: companies.membersOfacRequestStatus,
    tfaStatusChangeConfirmation: companies.tfaStatusChangeConfirmation,
    requestStatusGetUserList: companies.requestStatusGetUserList,
    editMember: companies.editMember,
    companies: companies.companies
});

const connectedMemberList = withRouter(connect(mapStateToProps)(MemberList));
export { connectedMemberList as MemberList };
