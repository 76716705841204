import classNames from 'classnames';
import { values, xorBy } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { bdInventoryActions } from '../../../actions/bd-inventory.actions';
import { controlPanelActions } from '../../../actions/control-panel.actions';
import { searchSecuritiesActions, } from '../../../actions/search.securities.actions';
import { constants } from '../../../constants/constants';
import { roles } from '../../../constants/roles';
import { routes } from '../../../constants/routes';
import { SORT, SORTING_TYPE } from '../../../constants/sort';
import { useAllToAllAgreementBlocked } from '../../../effects/useAllToAllAgreementBlocked';
import { useLocationStateReset } from '../../../effects/useLocationStateReset';
import { useSortedListByManyFields } from '../../../effects/useSortedListByManyFields';
import { dealerInventorySelector } from '../../../selectors/dealer-inventory.selector';
import IconSVG from '../../../styles/svg-icons';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { BwicFilterType } from '../../../types/filters/FilterState';
import { InventoryPosition } from '../../../types/inventory/InventoryPosition';
import { InventoryPositionStatus } from '../../../types/inventory/InventoryPositionStatus';
import { ColumnConfigKey } from '../../../types/page-config/column-customizer/ColumnConfigKey';
import { PageConfigType } from '../../../types/page-config/PageConfigType';
import { AppState } from '../../../types/state/AppState';
import { user } from '../../../user/user';
import { formatUtils } from '../../../utils/format.utils';
import { isRequestFailed, isRequestSuccess, isRequesting } from '../../../utils/request-state.utils';
import { moneyUtils } from '../../../utils/money.utils';
import { createColumnListBuilder } from '../../../utils/ColumnListBuilder';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { ActionBlocker } from '../../access/ActionBlocker';
import { FeatureButton } from '../../access/FeatureButton';
import { FeatureLink } from '../../access/FeatureLink';
import { getAmrColumns } from '../../amrPipeline/common-columns';
import { ControlPanelOptions } from '../../bidding/common/ControlPanelOptions';
import { Table } from '../../bidding/common/table';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { EmptyPlaceholder } from '../../common/EmptyPlaceholder';
import { OnHoverTooltip } from '../../common/OnHoverTooltip';
import { Preloader } from '../../common/Preloader';
import { Relative } from '../../common/Relative';
import { TickerButton } from '../../common/TickerButton';
import { BloombergLink } from '../../common/BloombergLink';
import { IntexLink } from '../../common/IntexLink';
import { BwicColumnCustomizer } from '../../common/pageConfig/column-customizer/BwicColumnCustomizer';
import { usePageConfig } from '../../common/pageConfig/usePageConfig';
import { ValitanaLink } from '../../common/ValitanaLink';
import { Checkbox } from '../../controls/Checkbox';
import { SecuritiesSearchInput } from '../../filters';
import { useManagersFilterData } from '../../filters/dinamicFilterEffects/useManagersFilterData';
import { RequestSettlementAgentAgreementBanner } from '../../request-settlement-agent-agreement/RequestSettlementAgentAgreementBanner';
import { SecurityDetailsPanel } from '../../security-details';
import { InventoryNewBwicButton } from '../InventoryNewBwicButton';
import { InventoryStatusLabel } from '../InventoryStatusLabel';
import { TradedButton } from '../TradedButton';
import { ViewBwicColumn } from '../ViewBwicColumn';
import { BDInventoryListFilter } from './filter/bdIInventoryListFilter';
import { InventoryTablePosition } from './InventoryTablePosition';
import { SendEmailPopup } from './SendEmailPopup';
import { ImDealLookupLink } from '../../amrPipeline/common/ImDealLookupLink';
import { BlockedFeatureContent, SubscribeLink } from '../../access/BlockedFeatureText';
import { RoleActionBlocker } from '../../access/RoleActionBlocker';
import { BdInventorySummuryRow } from './BdInventorySummuryRow';
import { appConfig } from '../../../app-config';
import { inventoryColumnLabels } from '../../../constants/inventoryColumnsLabels';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { InventoryPositionWithLock } from '../../../types/inventory/InventoryPositionWithLock';
import { inventoryEditActions } from '../../../actions/inventory-edit.actions';
import { ViewDealDocumentsButton } from '../../documents/deal-documents-popup/ViewDealDocumentButton';
import { createFilterActions } from '../../../actions/filter.actions';
import { securityDetailsActions } from '../../../actions';
import { bwicLogActions } from '../../../actions/bwic-log.actions';

const filterActions = createFilterActions(BwicFilterType.BDInventory);

export function BdInventoryList() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const pageConfigType = PageConfigType.Inventory;
    const {
        config, requestState: pageConfigRequestState, defaultConfigRequestState
    } = usePageConfig(pageConfigType, false, BwicFilterType.BDInventory);
    const columnsConfig = user.hasFeatures(SubscriptionFeature.InventoryColumnCustomizer)
        ? config?.columns
        : undefined;

    const isPageConfigLoaded = isRequestSuccess(pageConfigRequestState, defaultConfigRequestState)
        || isRequestFailed(pageConfigRequestState, defaultConfigRequestState);

    const [mounted, setMounted] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState<InventoryPosition | null>(null);
    const [sortDirection, setSortDirection] = useState(SORT.DESC);
    const [selectedSecurities, setSelectedSecurities] = useState<InventoryTablePosition[]>([]);
    const [sortField, setSortField] = useState('lastUpdateDate');
    const [isVisibleSendEmailPopup, setVisibleSendEmailPopup] = useState(false);
    const [sortedInventoryList, setSortedInventoryList] = useState<InventoryPosition[]>([]);

    const isInventoryExist = useSelector((state: AppState) => state.bdInventory.isInventoryExist);
    const requestStatusExportInventory = useSelector((state: AppState) => state.bdInventory.requestStatusExportInventory);
    const requestStateSearchInventory = useSelector((state: AppState) => state.entities.inventory.requestState);
    const lockModifiedDate = useSelector((state: AppState) => state.entities.inventory.lockModifiedDate);
    const inventoryList = useSelector(dealerInventorySelector);
    const requestStateSetTradedStatus = useSelector((state: AppState) => state.inventoryEdit.requestStateSetTradedStatus);
    const requestStateInventoryExist = useSelector((state: AppState) => state.bdInventory.requestStateInventoryExist);
    const securityDetails = useSelector((state: AppState) => state.securityDetails.security);

    const isSettlementAgent = user.current()?.isClearingBank;
    const isEmptyInventoryList = isRequestSuccess(requestStateSearchInventory) && !inventoryList.length;
    const isSelectedAll = selectedSecurities.length === sortedInventoryList.length;
    const isSelectedPartially = Boolean(selectedSecurities.length && !isSelectedAll);
    const unTradedInventoryPositions = useMemo(() => sortedInventoryList.filter(entity => entity.status !== InventoryPositionStatus.traded), [sortedInventoryList]);
    const allToAllAgreementBlocked = useAllToAllAgreementBlocked();

    const isShowSelectingItemCheckboxes = user.hasRoles(roles.BrokerDealerTrader) && !allToAllAgreementBlocked && !isSettlementAgent;

    const managersRequestState = useManagersFilterData(BwicFilterType.BDInventory);
    const isDynamicFiltersLoaded = isRequestSuccess(managersRequestState) || isRequestFailed(managersRequestState);
    const isLoading = !mounted || isRequesting(requestStateSearchInventory) || !isDynamicFiltersLoaded;
    const activeInventoryId = useSelector((s: AppState) => Number(s.securityDetails.security?.id ?? s.bwicLog.security?.id ?? s.companyDetails.rowKey ?? 0));
    const adavancedFiltersBlocked = useSelector((s: AppState) => s.bdInventory.advancedFilterBlocked);

    useLocationStateReset();

    useEffect(() => {
        setMounted(true);

        dispatch(bdInventoryActions.resetEntities());
        if (isDynamicFiltersLoaded)
            dispatch(bdInventoryActions.init());
        return () => {
            dispatch(searchSecuritiesActions.reset());
            dispatch(controlPanelActions.hide());
        }
    }, [dispatch, isDynamicFiltersLoaded]);

    const columns: IColumnDefinition<InventoryTablePosition>[] = useMemo(() => {
        const {
            collateralType,
            closingDate,
            nonCallPeriodEnd,
            reinvestmentPeriodEnd,
            dealName,
            manager,
            outOfNC,
            outOfRI,
            nonCallPeriodEndYears,
            reinvestmentPeriodEndYears,
            ...restAmrColumns
        } = getAmrColumns();
        const amrColumns = values(restAmrColumns);
        const list: IColumnDefinition<InventoryTablePosition>[] = [{
            columnKey: 'status',
            renderColumnHeaderContent: () => '',
            renderColumnContent: entity => <InventoryStatusLabel status={entity.status} />,
            className: 'data-list-cell-xxs',
            stickLeft: true
        }, {
            columnKey: 'lastUpdateDate',
            configKey: ColumnConfigKey.LastUpdateDate,
            renderColumnHeaderContent: () => 'Last Updated',
            renderColumnContent: entity =>
                <OnHoverTooltip
                    overlay={dateTimeUtils.utcToLocalString(entity.lastUpdateDate, constants.dateTimeFormat)}>
                    {moment.utc(entity.lastUpdateDate).format(constants.dateFormat)}
                </OnHoverTooltip>,
            className: 'data-list-cell-sm',
            sortingField: 'lastUpdateDate',
            sortingType: SORTING_TYPE.dateHours,
            stickLeft: true,
        }, {
            columnKey: 'ticker',
            configKey: ColumnConfigKey.Ticker,
            renderColumnHeaderContent: () => 'Ticker',
            renderColumnContent: entity =>
                <TickerButton
                    security={entity}
                    fetchAgencyRatings={true}
                    onClick={() => setSelectedPosition(null)}
                />,
            className: 'data-list-cell-lg',
            sortingField: 'ticker',
            sortingType: SORTING_TYPE.string,
            stickLeft: true
        },
            manager,
        ({ ...ViewBwicColumn, stickLeft: false } as IColumnDefinition<InventoryTablePosition>),
        {
            columnKey: 'currency',
            configKey: ColumnConfigKey.Currency,
            renderColumnHeaderContent: () => 'Ccy',
            renderColumnContent: entity => entity.currency,
            className: 'data-list-cell-xs',
            sortingField: 'currency',
            sortingType: SORTING_TYPE.string
        }, {
            columnKey: 'rating',
            configKey: ColumnConfigKey.Rating,
            renderColumnHeaderContent: () => 'Rtg',
            renderColumnContent: entity => entity.rating,
            className: 'data-list-cell-xs',
            sortingField: 'rating',
            sortingType: SORTING_TYPE.rating
        }, {
            columnKey: 'bidSize',
            configKey: ColumnConfigKey.BidSize,
            renderColumnHeaderContent: () => 'Bid Size, MM',
            renderColumnContent: entity => entity.bidSize ? moneyUtils.amountToMM(entity.bidSize) : constants.emptyPlaceholder,
            className: 'data-list-cell-sm text-right',
            sortingField: 'bidSize',
            sortingType: SORTING_TYPE.number
        }, {
            columnKey: 'size',
            configKey: ColumnConfigKey.Size,
            renderColumnHeaderContent: () => 'Offer Size, MM',
            renderColumnContent: entity => moneyUtils.amountToMM(entity.size),
            className: 'data-list-cell-sm text-right',
            sortingField: 'size',
            sortingType: SORTING_TYPE.number
        },
            collateralType,
        {
            columnKey: 'bid',
            configKey: ColumnConfigKey.Bid,
            renderColumnHeaderContent: () => 'Bid, %',
            renderColumnContent: entity => entity.bid ? entity.bid.toFixed(3) : constants.emptyPlaceholder,
            className: 'text-right',
            sortingField: 'bid',
            sortingType: SORTING_TYPE.number
        }, {
            columnKey: 'offer',
            configKey: ColumnConfigKey.Offer,
            renderColumnHeaderContent: () => 'Offer, %',
            renderColumnContent: entity => entity.offer ? entity.offer.toFixed(3) : constants.emptyPlaceholder,
            className: 'text-right',
            sortingField: 'offer',
            sortingType: SORTING_TYPE.number
        }, {
            columnKey: 'creditEnhancement',
            configKey: ColumnConfigKey.CreditEnhancement,
            renderColumnHeaderContent: () => 'C/E, %',
            renderColumnContent: entity => entity.creditEnhancement ? formatUtils.formatDecimal(entity.creditEnhancement) : constants.emptyPlaceholder,
            className: 'text-right',
            sortingField: 'creditEnhancement',
            sortingType: SORTING_TYPE.number
        }, {
            columnKey: 'bidDmBps',
            configKey: ColumnConfigKey.BidDmBps,
            renderColumnHeaderContent: () => 'Bid DM, bps',
            renderColumnContent: entity => entity.bidDmBps || constants.emptyPlaceholder,
            className: 'data-list-cell-sm text-right',
            sortingField: 'bidDmBps',
            sortingType: SORTING_TYPE.number
        }, {
            columnKey: 'offerDmBps',
            configKey: ColumnConfigKey.OfferDmBps,
            renderColumnHeaderContent: () => 'Offer DM, bps',
            renderColumnContent: entity => entity.offerDmBps || constants.emptyPlaceholder,
            className: 'data-list-cell-sm text-right',
            sortingField: 'offerDmBps',
            sortingType: SORTING_TYPE.number
        }, {
            columnKey: 'offerYield',
            configKey: ColumnConfigKey.OfferYield,
            renderColumnHeaderContent: () => 'Yld, %',
            renderColumnContent: entity => entity.offerYield ? entity.offerYield.toFixed(2) : constants.emptyPlaceholder,
            className: 'text-right',
            sortingField: 'offerYield',
            sortingType: SORTING_TYPE.number
        }, {
            columnKey: 'coupon',
            configKey: ColumnConfigKey.Coupon,
            renderColumnHeaderContent: () => 'Coupon',
            renderColumnContent: entity => formatUtils.formatInventoryCoupon(entity, constants.emptyPlaceholder),
            className: 'data-list-cell-lg',
        }, {
            columnKey: 'walYears',
            configKey: ColumnConfigKey.WalYears,
            renderColumnHeaderContent: () => 'WAL',
            renderColumnContent: entity => entity.walYears ? entity.walYears.toFixed(2) : constants.emptyPlaceholder,
            className: 'text-right',
            sortingField: 'walYears',
            sortingType: SORTING_TYPE.number
        }, {
            columnKey: 'mvoc',
            configKey: ColumnConfigKey.Mvoc,
            renderColumnHeaderContent: () => 'MVOC, %',
            renderColumnContent: entity => entity.mvoc ? entity.mvoc.toFixed(2) : constants.emptyPlaceholder,
            className: 'text-right',
            sortingField: 'mvoc',
            sortingType: SORTING_TYPE.number
        },
        { ...dealName, columnKey: ColumnConfigKey.DealName },
        { ...closingDate, columnKey: ColumnConfigKey.ClosingDate },
            nonCallPeriodEnd,
            nonCallPeriodEndYears,
            outOfNC,
            reinvestmentPeriodEnd,
            reinvestmentPeriodEndYears,
            outOfRI,
        ...amrColumns,
        {
            columnKey: 'description',
            configKey: ColumnConfigKey.Description,
            renderColumnHeaderContent: () => 'Note',
            renderColumnContent: entity =>
                <OnHoverTooltip overlay={entity.description} wrapperClass="note-text">
                    <div className="text-ellipsis">{entity.description || constants.emptyPlaceholder}</div>
                </OnHoverTooltip>,
            className: 'data-list-cell-note data-list-cell-md',
        }, {
            columnKey: 'deal-documents',
            configKey: ColumnConfigKey.DealDocuments,
            renderColumnHeaderContent: () => 'Docs',
            renderColumnContent: entity => entity.documentsCount
                ? <ViewDealDocumentsButton className="text-regular" security={entity} />
                : constants.emptyPlaceholder,
            className: '',
        }, {
            columnKey: 'traded',
            renderColumnHeaderContent: () => '',
            renderColumnContent: entity => (
                <RoleActionBlocker roles={[roles.BrokerDealerTrader]}>
                    <TradedButton
                        position={entity as InventoryPositionWithLock}
                        isTradedChangeInProgress={requestStateSetTradedStatus}
                        onTradedChange={(tradeDate) => dispatch(inventoryEditActions.setTradedStatus(entity.id, lockModifiedDate!, tradeDate))}
                    />
                </RoleActionBlocker>
            ),
            headerClassName: 'data-list-cell-xxs padding-r-0 text-right',
            bodyClassName: 'padding-r-0 text-right',
            stickRight: true
        }, {
            columnKey: 'imDealLookupLink',
            renderColumnHeaderContent: () => '',
            renderColumnContent: security =>
                <>
                    {
                        !!security.dealReferenceName &&
                        !!security.dealLegalName &&
                        <ImDealLookupLink
                            dealReferenceName={security.dealReferenceName}
                            dealLegalName={security.dealLegalName}
                        />
                    }
                </>,
            className: 'data-list-cell-xxs padding-r-0 text-right',
            stickRight: true
        }, {
            columnKey: 'intex',
            configKey: ColumnConfigKey.Actions,
            renderColumnHeaderContent: () => '',
            renderColumnContent: (entity) => (
                <IntexLink positionId={entity.isinCusip} />
            ),
            headerClassName: 'data-list-cell-xxs padding-r-0 text-right',
            bodyClassName: 'data-list-cell-xxs padding-r-0 text-right',
            stickRight: true
        }, {
            columnKey: 'bloomberg',
            configKey: ColumnConfigKey.Actions,
            renderColumnHeaderContent: () => '',
            renderColumnContent: (entity) => <BloombergLink identifier={entity.isinCusip} />,
            headerClassName: 'data-list-cell-xxs padding-l-0 text-right',
            bodyClassName: 'data-list-cell-xxs padding-r-0 text-right',
            stickRight: true
        }, {
            columnKey: 'valitana',
            configKey: ColumnConfigKey.Actions,
            renderColumnHeaderContent: () => 'Actions',
            renderColumnContent: security => <ValitanaLink identifier={security.isinCusip} />,
            headerClassName: 'padding-l-0 text-right',
            bodyClassName: 'data-list-cell-xxs padding-l-0 text-right',
            stickRight: true
        }];

        return list;

    }, [dispatch, lockModifiedDate, requestStateSetTradedStatus])

    const bdInventoryColumns = useMemo(() => {
        const checkboxColumn: IColumnDefinition<InventoryTablePosition> = {
            columnKey: 'checkbox',
            renderColumnHeaderContent: () => (
                <Checkbox
                    checked={isSelectedAll}
                    partially={isSelectedPartially}
                    disabled={!unTradedInventoryPositions.length}
                    onChange={() => setSelectedSecurities(isSelectedAll || isSelectedPartially ? [] : unTradedInventoryPositions)}
                />
            ),
            renderColumnContent: entity => (
                <Checkbox
                    checked={selectedSecurities.includes(entity)}
                    disabled={entity.status === InventoryPositionStatus.traded}
                    onChange={() => setSelectedSecurities(xorBy(selectedSecurities, [entity], 'securityId'))} />
            ),
            className: 'data-list-cell-icon',
            stickLeft: true
        };

        return createColumnListBuilder<InventoryTablePosition>(columnsConfig)
            .addWhen(() => isShowSelectingItemCheckboxes, checkboxColumn)
            .add(...columns)
            .result()
    }, [columns, isShowSelectingItemCheckboxes, isSelectedAll, isSelectedPartially, selectedSecurities, unTradedInventoryPositions, columnsConfig]);

    useSortedListByManyFields<InventoryPosition>(
        inventoryList,
        setSortedInventoryList,
        sortField,
        sortDirection,
        columns.find(c => c.sortingField === sortField)?.sortingType || '',
        sortRules
    );

    const handleTableRowClick = useCallback((entity: InventoryPosition) => {
        if (securityDetails) {
            dispatch(controlPanelActions.hide());
        }
        if (entity.id === selectedPosition?.id) {
            setSelectedPosition(null);
        } else {
            setSelectedPosition(entity);
        }
    }, [securityDetails, selectedPosition, dispatch]);

    const handleResetFilterAndSearch = useCallback(() => {
        dispatch(searchSecuritiesActions.reset());
        dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
        dispatch(securityDetailsActions.securityDetailsReset());
        dispatch(bdInventoryActions.clearFilter());
        dispatch(bwicLogActions.bwicLogReset());
    }, [dispatch]);

    const renderContent = useMemo(() => {
        if (adavancedFiltersBlocked) {
            return <BlockedFeatureContent className="empty-placeholder" inline text={<><SubscribeLink /> to see Inventory details</>} />
        }
        if (!isInventoryExist && isRequestSuccess(requestStateInventoryExist))
            return (
                <EmptyPlaceholder
                    title="No Current Inventory"
                    text={<>You have not added securities to your inventory yet.<br />To add securities, click “Add
                        Inventory” below.</>}
                >
                    <RoleActionBlocker roles={[roles.BrokerDealerTrader]}>
                        <FeatureButton className="btn btn-main" onClick={() => history.push(routes.inventoryEdit)}>
                            Add Inventory
                        </FeatureButton>
                    </RoleActionBlocker>
                </EmptyPlaceholder>
            );
        if (!inventoryList.length && isRequestSuccess(requestStateSearchInventory) && isInventoryExist)
            return <EmptyPlaceholder onResetClick={handleResetFilterAndSearch} />;
        if (isRequestSuccess(requestStateSearchInventory))
            return (
                <Table
                    dataItems={sortedInventoryList}
                    columns={bdInventoryColumns.map(c => new ColumnBuilder(c))}
                    onRowClick={(s: InventoryPosition) => handleTableRowClick(s)}
                    createSecurityCustomClassName={(entity: InventoryPosition) => classNames({
                        active: entity.id === activeInventoryId, 'sold-status': entity.status === InventoryPositionStatus.traded
                    }
                    )}
                    onSort={(field: string, direction: string) => {
                        setSortField(field);
                        setSortDirection(direction);
                    }}
                    defaultSortBy={sortField}
                    defaultSortByDirection={sortDirection}
                    sticky={true}
                />
            );
        return null;
    }, [
        isInventoryExist, requestStateInventoryExist, inventoryList, sortedInventoryList, bdInventoryColumns, history,
        handleTableRowClick, requestStateSearchInventory, sortField, sortDirection, activeInventoryId, adavancedFiltersBlocked, handleResetFilterAndSearch
    ]);

    return (
        <div className="container my-inventory">
            <div className="sub-header sub-header-pipeline">
                <div className="sub-header-row type01 flex-row">
                    <h1>Inventory</h1>
                    <Relative>
                        <SecuritiesSearchInput
                            disabled={isLoading || isEmptyInventoryList}
                            requiredFeature={SubscriptionFeature.InventorySecuritySearch}
                        />
                    </Relative>
                    <div className="flex-item-right controls">
                        <ActionBlocker feature={SubscriptionFeature.ExportInventory}>
                            <Preloader
                                small={true}
                                fullScreen={false}
                                inProgress={requestStatusExportInventory}
                                text="Exporting…"
                            >
                                <FeatureButton
                                    disabled={!inventoryList.length || isLoading}
                                    onClick={() => dispatch(bdInventoryActions.exportInventoryRequest(sortField, sortDirection))}
                                    className="btn-link"
                                >
                                    <IconSVG name="export" width={16} height={16} /> Export
                                </FeatureButton>
                            </Preloader>
                        </ActionBlocker>
                        {
                            isInventoryExist && (
                                <RoleActionBlocker roles={[roles.BrokerDealerTrader]}>
                                    <FeatureLink className="btn-link" path={routes.inventoryEdit} disabled={isLoading}>
                                        <IconSVG name="edit-pencil" width={16} height={16} /> Edit List
                                    </FeatureLink>
                                </RoleActionBlocker>
                            )
                        }
                        <button
                            disabled={!isInventoryExist}
                            className="btn-link"
                            onClick={() => history.push(routes.inventoryDocuments)}
                        >
                            <IconSVG name="upload" width={16} height={16} /> Upload Docs
                        </button>
                        <OnHoverTooltip
                            disabled={!isInventoryExist || !inventoryList.length}
                            overlay="You can send today's inventory list to your email."
                        >
                            <FeatureButton
                                className="btn-link"
                                disabled={!isInventoryExist || !inventoryList.length}
                                onClick={() => setVisibleSendEmailPopup(true)}
                            >
                                <IconSVG name="email" width={16} height={16} /> Email
                            </FeatureButton>
                        </OnHoverTooltip>
                        <InventoryNewBwicButton
                            count={selectedSecurities.length}
                            selectedSecurities={selectedSecurities}
                        />
                    </div>
                </div>
                <div className="sub-header-row type02 flex-row align-items-flex-start row-align-filters">
                    <BDInventoryListFilter />
                    <BwicColumnCustomizer
                        columnLabels={inventoryColumnLabels}
                        tabs={{ 'All': PageConfigType.Inventory }}
                        disabled={!isPageConfigLoaded || isLoading}
                        defaultTab={pageConfigType}
                        requiredFeature={SubscriptionFeature.InventoryColumnCustomizer}
                    />
                </div>
            </div>
            <RequestSettlementAgentAgreementBanner />
            {appConfig.isSummaryRowHidden ? null : <BdInventorySummuryRow inventoryList={inventoryList} />}
            <div className="inventory-cnt flex-row flex-row-full-size position-relative">
                <Preloader inProgress={isLoading}>{renderContent}</Preloader>
                <SecurityDetailsPanel
                    optionsExclude={[ControlPanelOptions.viewInventory]}
                />
            </div>
            {isVisibleSendEmailPopup && (
                <SendEmailPopup
                    onClose={() => setVisibleSendEmailPopup(false)}
                    onConfirm={(params => dispatch(bdInventoryActions.requestSendEmail(params)))}
                />
            )}
        </div>
    );
}

const sortRules = {
    default: [
        {
            sortingField: 'lastUpdateDate',
            sortingType: SORTING_TYPE.dateHours,
            direction: SORT.DESC,
        }, {
            sortingField: 'rating',
            sortingType: SORTING_TYPE.rating,
            direction: SORT.ASC
        }, {
            sortingField: 'ticker',
            sortingType: SORTING_TYPE.string,
            direction: SORT.ASC
        }
    ],
    lastUpdateDate: [
        {
            sortingField: 'lastUpdateDate',
            sortingType: SORTING_TYPE.dateHours,
        }, {
            sortingField: 'rating',
            sortingType: SORTING_TYPE.rating,
            direction: SORT.ASC
        }, {
            sortingField: 'ticker',
            sortingType: SORTING_TYPE.string,
            direction: SORT.ASC
        }
    ],
    rating: [
        {
            sortingField: 'rating',
            sortingType: SORTING_TYPE.rating,
        }, {
            sortingField: 'lastUpdateDate',
            sortingType: SORTING_TYPE.dateHours,
            direction: SORT.DESC,
        }, {
            sortingField: 'ticker',
            sortingType: SORTING_TYPE.string,
            direction: SORT.ASC
        }
    ],
    ticker: [
        {
            sortingField: 'ticker',
            sortingType: SORTING_TYPE.string,
        }, {
            sortingField: 'lastUpdateDate',
            sortingType: SORTING_TYPE.dateHours,
            direction: SORT.DESC,
        }, {
            sortingField: 'rating',
            sortingType: SORTING_TYPE.rating,
            direction: SORT.ASC
        },
    ]
};
